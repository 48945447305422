<template>
	<main id="main-content">
		<alerts
			:championshipId="championship.id"
			v-if="championship && championship.id"
		/>
		<loading-indicator vertical-space="500" v-if="loading" />
		<transition name="fade">
			<div v-if="championship && !loading">
				<championship-navigation
					:seoTitle="championship.seo_title"
					:logo="championship.full_page_json.logo"
					:sport="championship.sport"
					:navigation="championship.full_page_json.navigation"
					@selected-tab="selectTab"
					:champ-info-exists="champ_info_exists"
					:externalTicketLink="championship.external_ticket_link"
					:ticketHospitalityLink="
						championship.ticket_hospitality_link
					"
				/>
				<slideshow
					template="SlideshowSlider"
					section-title="Top Slider"
					:slideshowId="top_slideshow_id"
					:count="5"
					v-if="top_slideshow_id"
				/>
				<championship-stages
					:stages="preliminary_stages"
					v-if="preliminary_stages"
					:geoFilterEnabled="
						championship.full_page_json.geo_filter_enabled
					"
					:searchFilterEnabled="
						championship.full_page_json.search_filter_enabled
					"
				/>
				<championship-national-championship
					:event="national_championship"
					v-if="national_championship"
				/>
				<championship-future-sites
					:sites="future_sites"
					class="padding-y-32 bp-1024:padding-y-64"
					v-if="future_sites && future_sites.length"
				/>
				<slideshow
					template="SlideshowSliderAltParallax"
					section-title="Bottom Slider"
					:slideshowId="bottom_slideshow_id"
					:count="5"
					v-if="bottom_slideshow_id"
				/>
			</div>
		</transition>
	</main>
</template>

<script>
import {
	get,
	set_css_variables,
	add_satisfi_snippet,
	remove_satisfi_snippet,
} from '@/helpers';
import ChampionshipNavigation from '@/components/championship/ChampionshipNavigation';
import Alerts from '@/components/Alerts';
import Slideshow from '@/components/slideshow/Slideshow';
import ChampionshipStages from '@/components/championship/ChampionshipStages';
import ChampionshipNationalChampionship from '@/components/championship/ChampionshipNationalChampionship';
import ChampionshipFutureSites from '@/components/championship/ChampionshipFutureSites';
import LoadingIndicator from '@/components/common/LoadingIndicator';

export default {
	name: 'Championship',

	components: {
		ChampionshipNavigation,
		Alerts,
		Slideshow,
		ChampionshipStages,
		ChampionshipNationalChampionship,
		ChampionshipFutureSites,
		LoadingIndicator,
	},

	data: () => ({
		championship: null,
		loading: false,
		currentTab: 0,
	}),

	methods: {
		get_data() {
			return new Promise((resolve, reject) => {
				this.loading = true;

				const request_options = {
					type: 'championships',
					slug: this.$route.params.slug,
				};

				get(
					`${this.$root.proxy}/services/adaptive_components.ashx`,
					request_options,
				)
					.then(response => {
						if (!response.championships.length) {
							this.$router.push({ name: 'Missing' });
						}

						let _championship = response.championships[0];
						_championship.full_page_json = JSON.parse(
							_championship.full_page_json,
						);

						if (
							_championship.full_page_json.stages &&
							_championship.full_page_json.stages.length
						) {
							_championship.full_page_json.stages.forEach(
								(stage, i) => {
									if (i === 0) {
										stage.is_active = true;
									} else {
										stage.is_active = false;
									}
								},
							);
						}

						this.championship = _championship;

						this.loading = false;

						resolve(_championship);
					})
					.catch(error => {
						this.loading = false;
						console.log(error);
						reject(error);
						this.$router.push({ name: 'Missing' });
					});
			});
		},
		selectTab(tab) {
			this.currentTab = tab;
		},
		load() {
			this.get_data().then(response => {
				/* eslint-disable */

				set_css_variables({
					'--color-default-theme-brand-text':
						response.full_page_json.theme.default_theme_brand_text,
					'--color-primary':
						response.full_page_json.theme.primary_background,
					'--color-primary-hover':
						response.full_page_json.theme.primary_hover,
					'--color-text-on-primary':
						response.full_page_json.theme.primary_text,
					'--color-secondary':
						response.full_page_json.theme.secondary_background,
					'--color-secondary-hover':
						response.full_page_json.theme.primary_hover,
					'--color-text-on-secondary':
						response.full_page_json.theme.secondary_text,
				});

				let title =
					response.full_page_json.metadata &&
					response.full_page_json.metadata.title &&
					response.full_page_json.metadata.title.length > 0
						? response.full_page_json.metadata.title
						: `${response.division} ${response.sport.title} Tickets`;
				let description =
					response.full_page_json.metadata &&
					response.full_page_json.metadata.description &&
					response.full_page_json.metadata.description.length > 0
						? response.full_page_json.metadata.description
						: `Buy and sell ${response.division} ${response.sport.title} tickets for every game. Includes hospitality options, dates, schedules and location.`;
				let image =
					response.full_page_json.metadata &&
					response.full_page_json.metadata.image &&
					response.full_page_json.metadata.image.length > 0
						? this.$root.get_image_resizer_url(
								'https://d230gea5qwdu36.cloudfront.net' +
									response.full_page_json.metadata.image,
						  )
						: 'https://ncaatickets.com/img/share.jpg';

				this.setMetaSidearm(title, [
					{
						name: 'og:title',
						content: title,
					},
					{
						name: 'twitter:title',
						content: title,
					},
					{
						name: 'description',
						content: description,
					},
					{
						name: 'og:description',
						content: description,
					},
					{
						name: 'twitter:description',
						content: description,
					},
					{
						name: 'og:image',
						content: image,
					},
					{
						name: 'twitter:image',
						content: image,
					},
				]);
			});
		},
	},
	computed: {
		championship_id() {
			return this.$route.params.id;
		},
		preliminary_stages() {
			return this.championship.full_page_json.stages.filter(
				stage => !stage.is_national_championship,
			);
		},
		national_championship() {
			return this.championship.full_page_json.stages.find(
				stage => stage.is_national_championship,
			);
		},
		future_sites() {
			return this.championship.full_page_json.future_sites;
		},
		top_slideshow_id() {
			return this.championship.full_page_json.top_slideshow;
		},
		bottom_slideshow_id() {
			return this.championship.full_page_json.bottom_slideshow;
		},
		champ_info_exists() {
			return (
				this.championship.full_page_json.champ_info_slideshow !== null
			);
		},
	},
	created() {
		this.load();
	},
	// User can navigate between /championships/:slug and /championships/:slug/matchinfo.
	// We want the script to be maintained between navigations.
	beforeRouteEnter(to, from, next) {
		// march-madness
		if (to.params['slug'] === 'march-madness') {
			if (
				from.name &&
				from.name === 'Championship' &&
				from.params['slug'] === 'march-madness'
			) {
				// Maintain the snippet (i.e. do nothing)
			} else {
				add_satisfi_snippet(17800);
			}
		}

		// womens-final-four
		else if (to.params['slug'] === 'womens-final-four') {
			if (
				from.name &&
				from.name === 'Championship' &&
				from.params['slug'] === 'womens-final-four'
			) {
				// Maintain the snippet (i.e. do nothing)
			} else {
				add_satisfi_snippet(17801);
			}
		}

		next();
	},
	beforeRouteLeave(to, from, next) {
		// march-madness
		if (from.params['slug'] === 'march-madness') {
			if (
				to.name &&
				to.name === 'ChampionshipChampInfo' &&
				to.params['slug'] === 'march-madness'
			) {
				// Maintain the snippet (i.e. do nothing)
			} else {
				remove_satisfi_snippet();
			}
		}

		// womens-final-four
		if (from.params['slug'] === 'womens-final-four') {
			if (
				to.name &&
				to.name === 'ChampionshipChampInfo' &&
				to.params['slug'] === 'womens-final-four'
			) {
				// Maintain the snippet (i.e. do nothing)
			} else {
				remove_satisfi_snippet();
			}
		}

		next();
	},
	watch: {
		currentTab(val) {
			if (val === 0) {
			}
		},
		$route() {
			this.load();
		},
	},
};
</script>
