<template>
	<div v-click-outside="close_menu">
		<button
			class="c-navigation__toggle display-flex align-items-center cursor-pointer height-100 header-font font-size-16 margin-l-auto reset text-transform-uppercase text-on-primary"
			:class="{ 'is-toggled': menu_toggled }"
			@click.prevent="toggle_menu"
		>
			<span>
				<slot></slot>
			</span>
			<chevron-right-icon
				class="c-navigation__toggle-icon bp-768:margin-l-6 transition"
				size=".9x"
			/>
		</button>
		<div
			class="c-navigation__off-canvas bg-color-white overflow-y-auto position-absolute right-0 top-100 transition shadow-level-1"
			:class="[
				{ 'opacity-1 pointer-events-auto': menu_toggled },
				{ 'opacity-0 pointer-events-none': !menu_toggled },
			]"
			v-if="navigation.items.length"
		>
			<ul
				class="c-navigation__level-1 reset-list"
				v-if="mode === 'navigation'"
			>
				<li
					class="c-navigation__item--level-1 border-b"
					:class="{
						'border-b-none': index === navigation.items.length - 1,
					}"
					v-for="(item, index) in navigation.items"
					:key="index"
				>
					<navigation-link
						:_aria-label="item.title"
						_class="c-navigation__url--level-1 display-flex align-items-center padding-y-16 padding-x-32 text-safe text-decoration-none transition hover:text-decoration-underline"
						:_tab-index="menu_toggled ? '1' : '0'"
						:url="item.link"
						:title="item.title"
						_external-icon
					>
						<chevron-right-icon
							class="margin-r-6 transition"
							size=".9x"
						/>
						<span>{{ item.title }}</span>
					</navigation-link>
				</li>
			</ul>
			<ul
				class="c-navigation__level-1 reset-list"
				v-if="mode === 'social_links'"
			>
				<li
					class="c-navigation__item--level-1 border-b"
					v-if="navigation.facebook !== ''"
				>
					<a
						aria-label="Facebook"
						:href="navigation.facebook"
						class="c-navigation__url--level-1 display-flex align-items-center padding-y-16 padding-x-32 text-safe text-decoration-none transition hover:text-decoration-underline"
						title="Facebook"
						target="_blank"
					>
						<facebook-icon
							class="margin-r-6 transition text-brand-facebook"
							size="1.25x"
						>
						</facebook-icon>
						<span>Facebook</span>
					</a>
				</li>
				<li
					class="c-navigation__item--level-1 border-b"
					v-if="navigation.twitter !== ''"
				>
					<a
						aria-label="Twitter"
						:href="navigation.twitter"
						class="c-navigation__url--level-1 display-flex align-items-center padding-y-16 padding-x-32 text-safe text-decoration-none transition hover:text-decoration-underline"
						title="Twitter"
						target="_blank"
					>
						<!-- <twitter-icon
							class="margin-r-6 transition text-brand-twitter"
							size="1.25x"
						></twitter-icon> -->
						<img
							class="margin-r-6 transition"
							src="@/assets/icons/x-logo.svg"
							style="width: 16px; height: 16px"
						/>
						<span>Twitter</span>
					</a>
				</li>
				<li
					class="c-navigation__item--level-1 border-b"
					v-if="navigation.instagram !== ''"
				>
					<a
						aria-label="Twitter"
						:href="navigation.instagram"
						class="c-navigation__url--level-1 display-flex align-items-center padding-y-16 padding-x-32 text-safe text-decoration-none transition hover:text-decoration-underline"
						title="Instagram"
						target="_blank"
					>
						<instagram-icon
							class="margin-r-6 transition text-brand-instagram"
							size="1.25x"
						>
						</instagram-icon>
						<span>Instagram</span>
					</a>
				</li>
				<li
					class="c-navigation__item--level-1 border-b"
					v-if="navigation.youtube !== ''"
				>
					<a
						aria-label="Twitter"
						:href="navigation.youtube"
						class="c-navigation__url--level-1 display-flex align-items-center padding-y-16 padding-x-32 text-safe text-decoration-none transition hover:text-decoration-underline"
						title="Youtube"
						target="_blank"
					>
						<youtube-icon
							class="margin-r-6 transition text-brand-youtube"
							size="1.25x"
						>
						</youtube-icon>
						<span>Youtube</span>
					</a>
				</li>
				<li
					class="c-navigation__item--level-1 border-b"
					v-if="navigation.tiktok != null && navigation.tiktok != ''"
				>
					<!-- ^ navigation.tiktok was added later on and isn't guaranteed to exist, so the null check is required here. -->
					<a
						aria-label="TikTok"
						:href="navigation.tiktok"
						class="c-navigation__url--level-1 display-flex align-items-center padding-y-16 padding-x-32 text-safe text-decoration-none transition hover:text-decoration-underline"
						title="TikTok"
						target="_blank"
					>
						<!-- vue-feather-icons doesn't have a TikTok icon. -->
						<!-- https://github.com/feathericons/feather/issues/1079 -->
						<!-- https://simpleicons.org/?q=tiktok -->
						<div
							class="margin-r-6"
							style="width: 20px; height: 20px;"
						>
							<svg
								class="transition"
								role="img"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>TikTok</title>
								<path
									d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"
								/>
							</svg>
						</div>
						<span>TikTok</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import {
	FacebookIcon,
	// TwitterIcon,
	InstagramIcon,
	YoutubeIcon,
	ChevronRightIcon,
} from 'vue-feather-icons';
import NavigationLink from './NavigationLink.vue';

export default {
	name: 'NavigationDropDown',
	props: {
		navigation: {
			type: Object,
			required: true,
		},
		mode: {
			type: String,
			required: false,
			default: 'navigation',
		},
	},
	components: {
		NavigationLink,
		FacebookIcon,
		// TwitterIcon,
		InstagramIcon,
		YoutubeIcon,
		ChevronRightIcon,
	},
	data() {
		return {
			menu_toggled: false,
		};
	},
	computed: {
		has_social_links() {
			if (
				this.navigation.facebook !== '' ||
				this.navigation.twitter !== '' ||
				this.navigation.instagram !== '' ||
				this.navigation.youtube !== ''
			) {
				return true;
			}
			return false;
		},
	},
	methods: {
		open_menu() {
			this.menu_toggled = true;
		},
		close_menu() {
			this.menu_toggled = false;
		},
		toggle_menu() {
			this.menu_toggled = !this.menu_toggled;
		},
	},
};
</script>

<style lang="scss" scoped>
.s-navigation {
	height: var(--championship-header-height);
	top: 48px;
}
.c-navigation {
	&__logo-image {
		height: 60px;
	}
	&__toggle {
		&.is-toggled {
			.c-navigation__toggle-icon {
				transform: rotate(90deg);
			}
		}
	}
	&__off-canvas {
		height: calc(100vh - var(--full-header-height));
		width: 100%;
	}
}

@media screen and (min-width: 1024px) {
	.s-navigation {
		height: 105px;
	}
	.c-navigation {
		&__logo-image {
			height: 100px;
		}
		&__off-canvas {
			height: auto;
			width: 375px;
		}
	}
}
</style>
