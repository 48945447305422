var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.current_stages.length)?_c('section',{staticClass:"s-stages"},[_c('h2',{staticClass:"accessible-hide"},[_vm._v("Stages")]),_c('div',{staticClass:"position-relative padding-y-16 padding-x-24 transition transition-duration-25 transition-timing-function-ease\n\t\t\tbp-1024:padding-x-64",staticStyle:{"background-color":"rgba(0,0,0,0.08)"}},[(_vm.geoFilterEnabled || _vm.searchFilterEnabled)?_c('div',{staticClass:"display-flex align-items-flex-end"},[(_vm.geoFilterEnabled)?_c('div',{staticClass:"c-events__filter position-relative flex-item-1\n\t\t\t\t\tbp-1024:margin-l-auto bp-1024:flex-grow-0 bp-1024:flex-shrink-0 bp-1024:flex-basis-auto"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_state),expression:"selected_state"}],staticClass:"c-events__select reset bg-color-surface border-radius-rounded cursor-pointer display-block padding-l-32 padding-r-64 shadow-level-1 width-100\n\t\t\t\t\t\tbp-540:padding-r-96\n\t\t\t\t\t",staticStyle:{"height":"56px","line-height":"56px"},attrs:{"name":"filter-sport","id":"filter-sport"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_state=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"all"}},[_vm._v("All States")]),_vm._l((_vm.states),function(state){return _c('option',{key:state,domProps:{"value":state}},[_vm._v(" "+_vm._s(state)+" ")])})],2),_c('chevron-down-icon',{staticClass:"pointer-events-none position-absolute right-0 margin-r-16 top-50 transform-translateY-n50",attrs:{"size":"24"}})],1):_vm._e(),(_vm.searchFilterEnabled)?_c('div',{staticClass:"flex-item-1\n\t\t\t\t\tbp-1024:flex-grow-0 bp-1024:flex-shrink-0 bp-1024:flex-basis-auto",class:[
					{ 'margin-l-16': _vm.geoFilterEnabled },
					{ 'bp-1024:margin-l-auto': !_vm.geoFilterEnabled } ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_text),expression:"search_text"}],ref:"events_search",staticClass:"reset bg-color-surface border-radius-rounded padding-l-32 padding-r-48 shadow-level-1",staticStyle:{"height":"56px","width":"100%"},attrs:{"type":"text","name":"search","placeholder":"Search..."},domProps:{"value":(_vm.search_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search_text=$event.target.value}}})]):_vm._e()]):_vm._e()]),_vm._l((_vm.current_stages),function(stage,index){return _c('div',{key:index,staticClass:"component c-stage margin-y-32\n\t\t\tbp-1024:margin-y-0 bp-1024:border-b bp-1024:border-style-dashed",class:[
			{ 'is-active': stage.is_active },
			{
				'bp-1024:border-none': index === _vm.current_stages.length - 1,
			} ]},[_c('header',{staticClass:"c-stage__header cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.toggle_stage(index)}}},[_c('div',{staticClass:"c-stage__header-main display-flex align-items-center justify-content-space-between bg-color-primary padding-x-32 padding-y-16 bp-1024:padding-0\n\t\t\t\tbp-1024:padding-y-48 bp-1024:padding-x-64"},[_c('div',{staticClass:"c-stage__header-title display-flex align-items-center flex-item-1 bp-1024:order-1"},[_c('img',{staticStyle:{"filter":"invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%)"},attrs:{"src":require("@/assets/tickets-v2.svg"),"width":"30","height":"30","alt":""}}),_c('span',{staticClass:"flex-item-1 header-font font-size-30 text-on-primary text-transform-uppercase"},[_vm._v(_vm._s(stage.title))])]),_c('button',{staticClass:"c-stage__header-toggle button cursor-pointer reset bp-1024:margin-r-8 bp-1024:order-0 transition padding-x-16",class:[
						{
							'bg-color-white text-muted shadow-level-1': !stage.is_active,
						},
						{
							'bg-color-primary text-on-primary border-style-solid':
								stage.is_active,
						} ],staticStyle:{"border-width":"2px"}},[_c('span',{staticClass:"padding-r-12"},[_vm._v("Ticket Options")]),_c('x-icon')],1)])]),(stage.events && stage.events.length)?_c('div',{staticClass:"c-stage__content transition",class:[
				{ 'overflow-hidden': !stage.is_active },
				{
					'height-auto overflow-auto': stage.is_active,
				},
				{
					'bp-1024:margin-b-16': stage.is_active,
				} ]},[_c('championship-event-list',{staticClass:"c-stage__content-list",attrs:{"events":stage.events,"state-filter":_vm.selected_state,"search-filter":_vm.search_text}})],1):_vm._e()])})],2):_c('section',{staticClass:"margin-b-32"})}
var staticRenderFns = []

export { render, staticRenderFns }