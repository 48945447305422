var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"component c-championship c-championship--card"},[_c('NavigationLink',{tag:"component",attrs:{"_class":'c-championship__url display-block text-decoration-none',"url":_vm.data.links && _vm.data.links.length > 0
				? _vm.data.links[0].link_url
				: ''}},[_c('div',{staticClass:"display-flex\n\t\t\t\tbp-540:display-block"},[_c('div',{staticClass:"c-championship__media bg-color-light-background position-relative\n\t\t\t\t\tbp-540:aspect-ratio-16-9 bp-540:overflow-hidden"},[(
						_vm.data.primary_image &&
							_vm.data.primary_image.images.length
					)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
						_vm.$root.get_image_resizer_url(
							("https://d230gea5qwdu36.cloudfront.net" + (_vm.data.primary_image.images[0].image))
						)
					),expression:"\n\t\t\t\t\t\t$root.get_image_resizer_url(\n\t\t\t\t\t\t\t`https://d230gea5qwdu36.cloudfront.net${data.primary_image.images[0].image}`,\n\t\t\t\t\t\t)\n\t\t\t\t\t"}],staticClass:"c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100",attrs:{"alt":_vm.data.primary_image.images[0].alt}}):_c('img',{staticClass:"c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100",attrs:{"src":require("@/assets/champ_info_img.webp"),"alt":""}})]),_c('div',{staticClass:"c-championship__details padding-16 display-flex\n\t\t\t\t\tbp-768:padding-x-0 bp-540:display-block"},[_c('div',{staticClass:"c-championship__title display-flex align-items-center"},[_c('span',{staticClass:"flex-item-1 header-font font-size-18 line-height-100 text-transform-uppercase transition transition-timing-function-ease\n\t\t\t\t\t\t\tbp-1024:font-size-30"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])])])])]),_c('div',{staticClass:"tileOverlayTextWrapper"},_vm._l((_vm.data.links),function(link){return _c('NavigationLink',{key:link.link_id,tag:"component",attrs:{"url":link.link_url,"_class":"c-championship__url display-block text-decoration-none tileOverlayTextLink","_external-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
					link.link_text.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
				)}})])}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }