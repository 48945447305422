var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.site)?_c('div',{staticClass:"component c-future-site position-relative shadow-level-1",class:[
		{ 'c-future-site--final': _vm.final },
		{ 'c-future-site--prelim': _vm.preliminary } ]},[_c('div',{staticClass:"c-future-site__media aspect-ratio-1-1 bg-color-black overflow-hidden position-relative"},[(_vm.event_image && _vm.event_image[0].url != '')?_c('picture',{staticClass:"c-future-site__image utility-position-cover object-fit-cover"},[_vm._l((_vm.event_image),function(image,index){return _c('source',{key:index,attrs:{"srcset":_vm.$root.get_image_resizer_url(
						("" + (_vm.$root.proxy) + (image.url))
					),"alt":image.alt,"media":("(min-width: " + (image.breakpoint) + "px)")}})}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
					_vm.$root.get_image_resizer_url(
						("" + (_vm.$root.proxy) + (_vm.event_image[0].url))
					)
				),expression:"\n\t\t\t\t\t$root.get_image_resizer_url(\n\t\t\t\t\t\t`${$root.proxy}${event_image[0].url}`,\n\t\t\t\t\t)\n\t\t\t\t"}],staticClass:"object-fit-cover height-100 width-100",attrs:{"alt":_vm.event_image[0].alt}})],2):_c('img',{staticClass:"utility-position-cover object-fit-cover height-100 width-100",attrs:{"src":require("@/assets/placeholder.jpg"),"alt":""}})]),_c('div',{staticClass:"c-future-site__details font-size-14 position-absolute bottom-0 left-0 padding-24 text-white width-100 z-index-2\n\t\t\tbp-768:font-size-18\n\t\t",class:{
			'display-flex flex-direction-column justify-content-flex-end bp-768:flex-direction-row bp-768:align-items-flex-end bp-768:justify-content-flex-start bp-1024:padding-32': _vm.final,
		}},[_c('div',{staticClass:"c-future-site__info bp-768:margin-r-auto bp-768:padding-r-24"},[_c('div',{staticClass:"c-national__championship__title header-font font-size-30 line-height-150",class:[{ 'bp-768:font-size-48': _vm.final }]},[_vm._v(" "+_vm._s(_vm.site.city)+", "+_vm._s(_vm.site.state)+" ")]),_c('div',{staticClass:"c-future-site__locale font-size-14 text-shadow",class:{ '': _vm.final }},[_c('span',{staticClass:"c-future-site__locale-location font-weight-700"},[_vm._v(" "+_vm._s(_vm.site.title)+" ")]),_c('span',{staticClass:"margin-x-4 text-muted-on-dark"},[_vm._v(" | ")]),_c('span',{staticClass:"c-future-site__locale-stadium text-muted-on-dark"},[_vm._v(" "+_vm._s(_vm.site.facility)+" ")])])]),(_vm.site.link && _vm.site.link.link != '')?_c('ul',{staticClass:"c-future-site__options margin-t-12 reset-list"},[_c('li',{staticClass:"c-future-site__option"},[_c('a',{staticClass:"c-future-site__option-btn button --on-dark-alt",attrs:{"href":_vm.site.link.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.site.link.title))])])]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }