<template>
	<section class="s-future-sites" v-if="sites && sites.length">
		<h2
			class="header-font margin-0 line-height-100 text-brand font-size-30 padding-x-32 padding-b-16 bp-1024:padding-x-64 bp-1024:padding-b-32 bp-1024:font-size-36"
		>
			Future Sites
		</h2>
		<div
			class="component c-future-sites padding-x-32 bp-1024:padding-x-64 position-relative"
		>
			<championship-future-site-card
				class="padding-b-4"
				:site="site_final"
				:final="true"
				:preliminary="false"
				v-if="site_final"
			/>
			<swiper
				class="margin-t-4"
				ref="slider"
				:options="swiper_options"
				v-if="sites_preliminary && sites_preliminary.length"
			>
				<swiper-slide
					class="c-future-sites__item position-relative"
					v-for="(site, index) in sites_preliminary"
					:key="index"
				>
					<championship-future-site-card :site="site" />
				</swiper-slide>
			</swiper>
			<div
				class="display-flex align-items-center justify-content-center"
				v-if="sites_preliminary && sites_preliminary.length"
			>
				<div
					class="swiper-button-prev swiper-button-prev--future-sites button --icon margin-t-32 margin-r-8"
					slot="button-prev"
				>
					<arrow-left-icon size="24"></arrow-left-icon>
				</div>
				<div
					class="swiper-button-next swiper-button-next--future-sites button --icon margin-t-32 margin-l-8"
					slot="button-next"
				>
					<arrow-right-icon size="24"></arrow-right-icon>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons';
import ChampionshipFutureSiteCard from '@/components/championship/ChampionshipFutureSiteCard';

export default {
	name: 'FutureSites',

	props: {
		sites: {
			required: true,
			type: Array,
			default: () => [],
		},
	},

	components: {
		ArrowRightIcon,
		ArrowLeftIcon,
		ChampionshipFutureSiteCard,
	},

	data: () => ({
		swiper_options: {
			touchReleaseOnEdges: true,
			navigation: {
				nextEl: '.swiper-button-next--future-sites',
				prevEl: '.swiper-button-prev--future-sites',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
			spaceBetween: 8,
			slidesPerView: 1,
			breakpoints: {
				640: {
					slidesPerView: 2,
				},
				1440: {
					slidesPerView: 3,
				},
				1536: {
					slidesPerView: 4,
				},
			},
			autoplay: {
				delay: 3000,
			},
		},
	}),

	computed: {
		site_final() {
			return this.sites.find(s => s.final);
		},
		sites_preliminary() {
			return this.sites.filter(s => s.preliminary);
		},
		swiper() {
			if (this.$refs.slider) {
				return this.$refs.slider.$swiper;
			}
			return null;
		},
	},

	watch: {
		sites_preliminary: {
			handler() {
				if (this.swiper) {
					setTimeout(() => {
						this.swiper.autoplay.stop();
						this.swiper.slideTo(0, 0);
					}, 500);
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
	position: static;
	transform: none;

	&::after {
		content: none;
	}
}
</style>
