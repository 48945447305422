var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"component c-championship c-championship-new--card position-relative height-100 bg-color-white margin-y-10 bp-768:margin-y-0",class:[{ 'is-open': _vm.other_links_active }]},[_c('div',{staticClass:"c-championship__url display-block text-decoration-none display-flex flex-direction-column position-static height-100"},[_c('div',{staticClass:"c-championship__wrapper"},[_c('div',{class:'c-championship__media bg-color-light-background overflow-hidden' +
						(_vm.has_link ? ' has-link' : ''),on:{"click":_vm.on_image_click}},[(
						_vm.data.primary_image &&
							_vm.data.primary_image.images.length
					)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
						_vm.$root.get_image_resizer_url(
							("https://d230gea5qwdu36.cloudfront.net/" + (_vm.data.primary_image.images[0].image))
						)
					),expression:"\n\t\t\t\t\t\t$root.get_image_resizer_url(\n\t\t\t\t\t\t\t`https://d230gea5qwdu36.cloudfront.net/${data.primary_image.images[0].image}`,\n\t\t\t\t\t\t)\n\t\t\t\t\t"}],staticClass:"c-championship__image height-100 object-fit-cover object-position-c transition width-100 top-0",attrs:{"alt":_vm.data.primary_image.images[0].alt}}):_c('img',{staticClass:"c-championship__image height-100 object-fit-cover object-position-c transition width-100 top-0",attrs:{"src":require("@/assets/champ_info_img.webp"),"alt":""}})]),_c('div',{staticClass:"c-championship__details padding-16 margin-b-auto padding-32"},[_c('div',{staticClass:"c-championship__title display-flex flex-direction-column"},[_c('div',{staticClass:"flex-item-1 header-font font-size-18 line-height-100 text-transform-uppercase transition transition-timing-function-ease\n\t\t\t\t\t\tbp-1024:font-size-30"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])])])]),_c('div',{staticClass:"c-championship__more text-brand text-transform-uppercase header-font margin-t-auto padding-b-32 padding-x-32 hover:text-info"},[_c('div',_vm._l((_vm.data.links),function(link){return _c('navigation-link',{key:link.link_id,attrs:{"url":link.link_url,"_class":"c-championship__more--option button text-white margin-r-4 margin-y-4 bg-color-primary hover:bg-color-secondary"}},[_vm._v(" "+_vm._s(link.link_text)+" ")])}),1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }